<div class="container mb-5">
    <main role="main" class="container">
        <div class="primary-block p-3 shadow rounded-3">
            <div class="container">
                <h2>Création d'un centre de retraitement</h2>
                <div class="row mt-4">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div *ngIf="error" class="alert alert-danger" role="alert">
                            Veuillez fournir le nom du centre de retraitement.
                        </div>

                        <div class="card m-1">
                            <h4 class="card-header">Centre de retraitement</h4>
                            <div class="card-body"> 
                                <div class="card-text">
                                    <form #formUpdateProvider="ngForm" (ngSubmit)="onSubmitProvider(formUpdateProvider.value)">
                                        <div class="input-group mb-2">
                                            <span class="input-group-text">Nom</span>
                                            <input type="text" class="form-control" ngModel id="nom" name="nom" placeholder="Nom du centre de retraitement..." aria-label="nom">
                                        </div>
                                        <div class="d-flex justify-content-end bd-highlight">
                                            <button class="btn btn-warning ms-2" type="submit" value="submit">Enregistrer et passer aux étapes suivantes</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
