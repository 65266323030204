<main role="main">
    <div class="rounded-3">
      <div class="primary-block p-3 mb-4 shadow rounded-3">
        <div class="container">
          <div *ngIf="livraison">
            <h1 class="display-4">Livraison {{livraison.serialNumber | livraisonBarcode: livraison.provider.id}}</h1>
            <p *ngIf="livraison.creationDate > '1970-01-01'" class="lead">Créée le {{livraison.creationDate | date:'dd MMMM y à HH:mm'}}</p>
            <p *ngIf="livraison.validationDate > '1970-01-01'" class="lead">Validée par ASI le {{livraison.validationDate | date:'dd MMMM y à HH:mm'}}</p>
            <p *ngIf="livraison.shipmentDate > '1970-01-01'" class="lead">Envoyée au Franchisé le {{livraison.shipmentDate | date:'dd MMMM y à HH:mm'}}</p>
            <p *ngIf="livraison.status === 4 && isAdmin && livraison.receivedDate" class="lead">Reçu le {{livraison.receivedDate | date:'dd MMMM y à HH:mm'}}</p>
    
            <!--<div *ngIf="livraison.status === 4 && livraisonStock">
              <p class="lead">Ajout des FS© au Stock le {{livraisonStock.date_ajout | date:'dd MMMM y à HH:mm'}}</p>
            </div>-->
    
            <br>
    
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Statut</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{livraison.status | livraisonStatus}}</h6>
                  </div>
                </div>
              </div>
    
              <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="card" style="width: 18rem; height: 7rem;">
                  <div class="card-body">
                    <h5 class="card-title">Nombre de Palettes</h5>
                    <h6 class="card-subtitle mb-2 text-muted">{{livraison.nbPalettes}}</h6>
                  </div>
                </div>
              </div>
    
              <ng-container *ngIf="livraison.franchise.id > 0; else noFranchise">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Franchisé</h5>
                      <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                        <select [(ngModel)]="livraison.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                          <option *ngFor="let f of franchises" [ngValue]="f.id">
                            <ng-container *ngIf="f.id > 0; else noFranchise">
                              {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                            </ng-container>
                            <ng-template #noFranchise>
                              <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                            </ng-template>
                          </option>
                        </select>
                      </ng-container>
                      <ng-template #notAdmin>
                        <h6 class="card-subtitle mb-2 text-muted">{{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]</h6>
                      </ng-template>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="livraison.address.id > 0; else noAddress">
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card" style="width: 18rem; height: 7rem;">
                      <div class="card-body">
                        <h5 class="card-title">Adresse de livraison</h5>
                        <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                          <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="adresseSelect" class="form-select" id="adresseSelect">
                            <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                              <ng-container *ngIf="a.id > 0 && a.isActif === 1; else noAddress">
                                {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                              </ng-container>
                              <ng-template #noAddress>
                                <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                              </ng-template>
                            </option>
                          </select>
                        </ng-container>
                        <h6 class="card-subtitle mb-2 text-muted">
                          <p *ngIf="livraison.address.complement_adresse; else noComp">
                            {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                            {{livraison.address.code_postale}} {{livraison.address.ville}}
                          </p>
                        </h6>
                        <ng-template #noComp>
                          {{livraison.address.adresse}}<br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}}
                        </ng-template>
                        <ng-template #notAdmin>
                          <h6 class="card-subtitle mb-2 text-muted">
                            <!-- SUPPRIMER en dessous -->
                            <div class="row" *ngIf="decodedToken.username === 'jcarrat'">
                              <div class="col-9">
                                <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                                  <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                                    <ng-container *ngIf="a.id > 0; else noAddress">
                                      {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}}
                                    </ng-container>
                                    <ng-template #noAddress>
                                      <h6 class="card-subtitle text-muted">Ø</h6>
                                    </ng-template>
                                  </option>
                                </select>
                              </div>
                              <div class="col-2">
                                <ng-container *ngIf="isAdmin && livraison.franchise && livraison.franchise.id > 0 && livraison.provider.id > 0 && livraison.nbPalettes > 0">
                                  <button appNoDblClick (click)="updateLivraison($event)" class="btn btn-success" type="button">
                                    <i class="bi bi-save"></i>
                                  </button>
                                  <br>
                                </ng-container>
                              </div>
                            </div>
                            <!-- SUPPRIMER au dessus -->
                            <!-- <p *ngIf="livraison.address.complement_adresse; else noComp">
                              {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                              {{livraison.address.code_postale}} {{livraison.address.ville}}
                            </p>
                            <ng-template #noComp>
                              {{livraison.address.adresse}}<br />
                              {{livraison.address.code_postale}} {{livraison.address.ville}}
                            </ng-template> -->
                            
                          </h6>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-template #noAddress>
                  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div class="card" style="width: 18rem; height: 7rem;">
                      <div class="card-body">
                        <h5 class="card-title">Adresse de livraison</h5>
                        <ng-container>
                          <div class="row">
                            <div class="col-9">
                              <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                                <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                                  <ng-container *ngIf="a.id > 0; else noAddress">
                                    {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                                  </ng-container>
                                  <ng-template #noAddress>
                                    <h6 class="card-subtitle text-muted">Ø</h6>
                                  </ng-template>
                                </option>
                              </select>
                            </div>
                            <div class="col-2">
                              <ng-container *ngIf="isAdmin && livraison.franchise && livraison.franchise.id > 0 && livraison.provider.id > 0 && livraison.nbPalettes > 0">
                                <button appNoDblClick (click)="updateLivraison($event)" class="btn btn-success" type="button">
                                  <i class="bi bi-save"></i>
                                </button>
                                <br>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                        <h6 class="card-subtitle mb-2 text-muted">
                          <p *ngIf="livraison.address.complement_adresse; else noComp">
                            {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                            {{livraison.address.code_postale}} {{livraison.address.ville}}
                          </p>
                        </h6>
                        <ng-template #noComp>
                          {{livraison.address.adresse}}<br />
                          {{livraison.address.code_postale}} {{livraison.address.ville}}
                        </ng-template>
                        <!-- <h6 class="card-subtitle text-muted">
                          <select [(ngModel)]="livraison.address.id" (ngModelChange)="onChangeAddress($event)" name="adresseSelect" class="form-select" id="adresseSelect">
                            <option *ngFor="let a of activeAdresses" [ngValue]="a.id">
                              <ng-container *ngIf="a.id > 0 && a.isActif === 1; else noAddress">
                                {{a.adresse}} <p *ngIf="a.complement_adresse">{{a.complement_adresse}},</p> {{a.code_postale}} {{a.ville}} | 19T: {{a.accessibilite_19T ? 'Oui': 'Non'}}
                              </ng-container>
                              <ng-template #noAddress>
                                <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                              </ng-template>
                            </option>
                          </select>
                        </h6> -->
                        <ng-template #notAdmin>
                          <h6 class="card-subtitle mb-2 text-muted">
                            
                            <p *ngIf="livraison.address.complement_adresse; else noComp">
                              {{livraison.address.adresse}}, {{livraison.address.complement_adresse}} <br />
                              {{livraison.address.code_postale}} {{livraison.address.ville}}
                            </p>
                            <ng-template #noComp>
                              {{livraison.address.adresse}}<br />
                              {{livraison.address.code_postale}} {{livraison.address.ville}}
                            </ng-template>
                          </h6>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </ng-template>
    
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Accessibilité 19T</h5>
                      <h6 class="card-subtitle mb-2 text-muted">{{livraison.address.accessibilite_19T ? 'Oui' : 'Non'}}</h6>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #noFranchise>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Franchisé</h5>
                      <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                        <select [(ngModel)]="livraison.franchise.id" (ngModelChange)="onChangeFranchise($event)" name="franchiseSelect" class="form-select" id="franchiseSelect">
                          <option *ngFor="let f of franchises" [ngValue]="f.id">
                            <ng-container *ngIf="f.id > 0; else noFranchise">
                              {{f.nomPart.toUpperCase()}} {{f.prenomPart}} [{{f.initiales.toUpperCase()}}]
                            </ng-container>
                            <ng-template #noFranchise>
                              <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                            </ng-template>
                          </option>
                        </select>
                      </ng-container>
                      <ng-template #notAdmin>
                        <h6 class="card-subtitle mb-2 text-muted" *ngIf="livraison.franchise.id > 0; else noFranchise">{{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]</h6>
                        <ng-template #noFranchise>
                          <h6 class="card-subtitle mb-2 text-muted">Ø</h6>
                        </ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-template>
    
              <ng-container *ngIf="livraison.provider">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Centre</h5>
    
                      <ng-container *ngIf="isAdmin && livraison.status === 0; else notAdmin">
                        <select [(ngModel)]="livraison.provider.id" (ngModelChange)="onChangeProvider($event)" name="providerSelect" class="form-select" id="providerSelect">
                          <option *ngFor="let p of providers" [ngValue]="p.id">{{p.nom}}</option>
                        </select>
                      </ng-container>
                      <ng-template #notAdmin>
                        <h6 class="card-subtitle mb-2 text-muted">{{livraison.provider.nom}}</h6>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="livraison.franchise && retours">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Retour associé</h5>
                        <select *ngIf="isAdmin && livraison.status === 0" [(ngModel)]="associatedRetour" (ngModelChange)="onChangeRetour($event)" name="retourSelect" class="form-select mb-2" id="retourSelect">
                          <option [ngValue]="undefined">Aucun</option>
                          <option *ngFor="let r of retours" [ngValue]="r" [disabled]="r.status === 3">
                            {{ retourBarcodePipe(r) }} - Statut : {{ retourStatusPipe(r) }} - {{ retourDate(r) }}
                            <!--<ng-container *ngIf="r.associated_livraison">(Associé à livraison {{ r.associated_livraison }})</ng-container>-->
                          </option>
                        </select>
                        <h6 *ngIf="associatedRetour" class="card-subtitle mb-2 text-muted">
                          <a href="#" class="btn btn-secondary" routerLink="/retour/{{ associatedRetour.id }}">
                            {{ retourBarcodePipe(associatedRetour) }} - Statut : {{ retourStatusPipe(associatedRetour) }} - {{ retourDate(associatedRetour) }}
                          </a>
                        </h6>
                        <h6 *ngIf="!associatedRetour" class="card-subtitle mb-2 text-muted">Aucun</h6>
                    </div>
                  </div>
                </div>
              </ng-container>
    
              <ng-container *ngIf="livraison && lastLivraison">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div class="card" style="width: 18rem; height: 7rem;">
                    <div class="card-body">
                      <h5 class="card-title">Précédente Livraison</h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        <a class="btn btn-secondary btn-sm" target="_blank" href="/livraison/{{lastLivraison.id}}" role="button">{{lastLivraison.serialNumber | livraisonBarcode: lastLivraison.provider.id}}</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </ng-container>
    
            </div> <!-- row -->
            
            <br>
    
            <div *ngFor="let m of orderPalettes">
              <div class="col-md-11">
                <div class="input-group mb-3">
                  <div >
                    <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
                  </div>
                  <select class="form-select" disabled>
                    <option selected value="{{m.modelePalette}}">
                      {{ m.modelePalette | paletteModele }}
                    </option>
                  </select>
                  <div class="nbPaletteBox" disabled>
                    <input autocomplete="false" class="form-control input-group-text nbPaletteValueBox" type="number" value="{{m.nbPalettes}}" readonly>
                  </div>
                </div>
              </div>
            
              <div *ngIf="isAdmin && livraison.status === 0" class="col-md-1" id="buttonsBox">
                <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton" style="visibility: visible;">
                  Supprimer
                </button>
              </div>
            </div>
            
            <form *ngIf="isAdmin && livraison.status === 0 && paletteModeles !== undefined" autocomplete="off">
              <div class="row" id="active-form-row">
                <div class="col-sm-6 col-md-6 col-lg-8 col-xl-9">
                  <div class="input-group mb-3">
                    <div >
                      <label class="input-group-text" for="inputGroupSelectPalette">Palette</label>
                    </div>
                    <select (change)="paletteChanged($event)" class="form-select" id="inputGroupSelectPalette">
                      <option selected>Sélectionner...</option>
                      <option *ngFor="let m of paletteModeles" value="{{m.id}}">{{m.nom}}</option>
                    </select>
                    <div  id="minusPalette">
                      <button (click)="minusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonMinusCircle" disabled>
                        <i class="bi bi-dash-circle"></i>
                      </button>
                    </div>
                    <div class="nbPaletteBox" id="nbPalettes">
                      <input autocomplete="new-password" (input)="nbPaletteInputed($event)" type="number" value="0" min="1" class="form-control input-group-text nbPaletteValueBox" id="nbPaletteValue" disabled>
                    </div>
                    <div  id="plusPalette">
                      <button (click)="plusClicked($event)" class="btn btn-outline-secondary" type="button" id="inputGroupFileAddonPlusCircle" disabled>
                        <i class="bi bi-plus-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
    
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" id="buttonsBox">
                  <button appNoDblClick (click)="validatePalette($event)" class="btn btn-outline-success me-3" type="button" id="addPaletteButton" disabled>
                    <!--<i class="bi bi-plus"></i>-->
                    Valider
                  </button>
                  <button appNoDblClick (click)="removePalette($event)" class="btn btn-outline-danger" type="button" id="removePaletteButton">
                    <!--<i class="bi bi-x-lg"></i>-->
                    Supprimer
                  </button>
                </div>
              </div>
            </form>
    
            <ng-container *ngIf="isAdmin && livraison.status === 0 && livraison.franchise && livraison.franchise.id > 0 && livraison.nbPalettes > 0">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="showDemandeConfirmationLivraisonPreview(infoModal, emailPreviewModal)" class="d-none btn btn-danger btn-lg" type="button" [disabled]="isFetchingEmailPreview || tasks.mailDemandeLivraison">
                    <i class="bi bi-envelope"></i>
                    Aperçu de la Demande de confirmation de livraison au franchisé
                  </button>
                  <button appNoDblClick (click)="validateLivraison($event)" class="btn btn-success btn-lg" type="button">
                    <i class="bi bi-save"></i>
                    Valider la Livraison
                  </button>
                </div>
              </div>
              <br>
            </ng-container>
    
            <ng-container *ngIf="livraison.franchise && livraison.provider.id > 0">
              <ng-container *ngIf="livraison.status === 1 && livraison.franchise && livraison.nbPalettes === palettes.length">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="saveLivraison($event)" class="btn btn-success btn-lg" type="button">
                      <i class="bi bi-save"></i>
                      Finaliser la Livraison
                    </button>
                  </div>
                </div>
                <br>
              </ng-container>
    
              <!--<ng-container *ngIf="livraison.status === 2 && !livraison.validateByASI && isAdmin">
                <div class="row">
                  <div class="d-grid gap-2">
                    <a class="btn btn-success btn-lg" href="#" [routerLink]="['/livraison', livraison.id, 'validate']">
                    <i class="bi bi-search"></i>
                    Contrôler
                  </a>
                  </div>
                </div>
                <br>
              </ng-container>-->
    
              <!--<div *ngIf="livraison.validateByASI && livraison.status >= 3 && palettes?.length > 0">-->
              <ng-container *ngIf="livraison.status >= 2 && livraison.status !== 5 && livraison.status !== 6 && palettes.length > 0">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showPdfBonTransport()" class="btn btn-primary btn-lg" type="button">
                      <i class="bi bi-printer"></i>
                      Imprimer l'étiquette de transport
                    </button>
                  </div>
                </div>
                <br>
              </ng-container>
    
              <!--<ng-container *ngIf="livraison.validateByASI && livraison.status >= 3 && isAdmin">-->
              <ng-container *ngIf="livraison.status >= 2 && livraison.status !== 5 && livraison.status !== 6 && isAdmin">
                <!-- <ng-container *ngIf="blobDetailLivraison"> -->
                  <div class="row">
                    <div class="d-grid gap-2">
                      <button appNoDblClick (click)="showPdfDetailLivraison()" class="btn btn-danger btn-lg" type="button">
                        <i class="bi bi-printer"></i>
                        Imprimer le détail de Livraison
                      </button>
                    </div>
                  </div>
                  <br>
                <!-- </ng-container> -->
              </ng-container>
    
              <!--<ng-container *ngIf="livraison.validateByASI && livraison.status >= 3 ">-->
              <ng-container *ngIf="livraison.status >= 2 && livraison.status !== 5 && livraison.status !== 6">
                <!-- <ng-container *ngIf="blobBonLivraison"> -->
                  <div class="row">
                    <div class="d-grid gap-2">
                      <button appNoDblClick (click)="showPdfBonLivraison()" class="btn btn-danger btn-lg" type="button">
                        <i class="bi bi-printer"></i>
                        Imprimer la Demande de Livraison
                      </button>
                    </div>
                  </div>
                  <br>
                <!-- </ng-container> -->
              </ng-container>

              <ng-container *ngIf="livraison.status >= 2 && livraison.status !== 5 && livraison.status !== 6 && isAdmin">
                <!-- <ng-container *ngIf="blobBonLivraison"> -->
                  <div class="row">
                    <div class="d-grid gap-2">
                      <button appNoDblClick (click)="showCG()" class="btn btn-danger btn-lg" type="button">
                        <i class="bi bi-printer"></i>
                        Imprimer les Conditions Générales de Mise à Disposition
                      </button>
                    </div>
                  </div>
                  <br>
                <!-- </ng-container> -->
              </ng-container>

              <ng-container *ngIf="livraison.status === 3">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showLivraisonValideeFranchisePreview(infoModal, emailPreviewModal)" class="d-none btn-danger btn-lg" type="button" [disabled]="isFetchingEmailPreview || tasks.mailLivraisonValidee">
                      <i class="bi bi-envelope"></i>
                      Aperçu Livraison validée franchisé
                    </button>
                  </div>
                </div>
                <br>
              </ng-container>
    
              <ng-container *ngIf="livraison.status === 6 && isAdmin">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showDevisTransportPreview(infoModal, emailPreviewModal)" class="d-none btn-danger btn-lg" type="button" [disabled]="isFetchingEmailPreview || tasks.mailDevis">
                      <i class="bi bi-envelope"></i>
                      Aperçu de la demande de devis
                    </button>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showDemandeAttributionPalettesPreview(infoModal, emailPreviewModal)" class="d-none btn-danger btn-lg" type="button" [disabled]="isFetchingEmailPreview || tasks.mailAttributionPalettes">
                      <i class="bi bi-envelope"></i>
                      Aperçu de la demande d'attribution de palettes (centre)
                    </button>
                  </div>
                </div>
                <br>
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="showValidationLivraisonTransport(infoModal, emailPreviewModal)" class="d-none btn-danger btn-lg" type="button" [disabled]="isFetchingEmailPreview || tasks.mailValidationLivraison">
                      <i class="bi bi-envelope"></i>
                      Aperçu Validation de la livraison
                    </button>
                  </div>
                </div>
                <br>
              </ng-container>

              <ng-container *ngIf="isAdmin && livraison && livraison.franchise">
                <ng-container *ngIf="livraison.status === 2 && !livraison.validateByASI">
                  <div class="row">
                    <div class="d-grid gap-2">
                      <button appNoDblClick (click)="validateControleLivraison()" class="btn btn-success btn-lg" type="button">
                        <i class="bi bi-check-lg"></i>
                        Valider la Livraison à {{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]
                      </button>
                    </div>
                  </div>
                  <br>
                </ng-container>
              </ng-container>
    
              <ng-container *ngIf="livraison.validateByASI && livraison.status === 3 && livraison.franchise && palettes.length > 0">
                <div class="row">
                  <div class="d-grid gap-2">
                    <button appNoDblClick (click)="sendLivraison($event)" class="btn btn-success btn-lg" type="button">
                      <i class="bi bi-truck"></i>
                      Envoyer en Livraison à {{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]
                    </button>
                </div>
                </div>
                <br>
              </ng-container>
    
              <!--<ng-container *ngIf="isAdmin && livraison.status === 4 && !livraisonStock">
                <div class="row">
                  <button appNoDblClick (click)="addLivraisonToStock($event)" class="btn btn-warning btn-lg" type="button">
                    <div class="d-grid gap-2"></div>
                    <i class="bi bi-boxes"></i>
                    Ajouter les FS© au Stock de {{livraison.franchise.nomPart.toUpperCase()}} {{livraison.franchise.prenomPart}} [{{livraison.franchise.initiales.toUpperCase()}}]
                  </button>
                </div>
                <br>
              </ng-container>-->
    
            </ng-container>

            <ng-container *ngIf="livraison.status === 6 && isAdmin">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="confirmValidateDevis(validateDevisConfirmModal);" class="btn btn-success btn-lg" type="button">
                    <i class="bi bi-check2-square"></i>
                    Valider le devis
                  </button>
                </div>
              </div>
              <br>
            </ng-container>

            <ng-container *ngIf="isAdmin">
              <div class="row">
                <div class="d-grid gap-2">
                  <button appNoDblClick (click)="confirmCancelLivraison(cancelLivraisonConfirmModal); " class="btn btn-danger btn-lg" type="button" [disabled]="livraison.status >= 4 && livraison.status !== 6">
                    <i class="bi bi-x-lg"></i>
                    Annuler la livraison
                  </button>
                </div>
              </div>
              <br>
            </ng-container>
    
          </div>
    
        </div> <!-- container -->
      </div> <!-- jumbotron -->
    
      <div *ngIf="isAdmin && documents">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <h3>Progression de la livraison</h3>
    
                <section class="m-5 pt-4 timeline-border d-flex justify-content-between">
                  <ng-container *ngFor="let step of timelineSteps">

                    <ng-container *ngIf="livraison.status === 5">
                      <div class="timeline-box mt-2 position-relative rounded-3 timeline-cancelled">
                        <span class="timeline-icon">
                          <i class="bi bi-x-lg m-0"></i>
                        </span>
                        <div class="p-3">
                          <p class="h4 m-0 mb-2 text-center"><i class="bi {{step.icon}}"></i></p>
                          <h6 class="m-0 text-center">{{step.name}}</h6>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="livraison.status === 6">
                      <div class="timeline-box mt-2 position-relative rounded-3 
                        {{ step.status > 0 ? 'timeline-coming' : '' }}
                        {{ step.status < 1 ? 'timeline-done' : '' }}
                        {{ step.status === 6 ? 'timeline-in-progress' : '' }}
                        ">
                        <span class="timeline-icon">
                          <i class="bi 
                            {{ step.status > 0 ? 'bi-clock-history' : '' }}
                            {{ step.status < 1 ? 'bi-check-lg' : '' }}
                            {{ step.status === 6 ? 'bi bi-hourglass-split' : '' }} 
                            m-0"></i>
                        </span>
                        <div class="p-3">
                          <p class="h4 m-0 mb-2 text-center"><i class="bi {{step.icon}}"></i></p>
                          <h6 class="m-0 text-center">{{step.name}}</h6>
                        </div>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="livraison.status !== 6 && livraison.status !== 5">
                      <div class="timeline-box mt-2 position-relative rounded-3 
                        {{ livraison.status < step.status ? 'timeline-coming' : '' }}
                        {{ livraison.status === step.status ? 'timeline-in-progress' : '' }}
                        {{ livraison.status > step.status ? 'timeline-done' : '' }}
                        {{ (step.status === 6 && livraison.status > 0 ||
                            (step.status === livraison.status) && [3, 4].includes(step.status)) ? 'timeline-done' : '' }}
                        ">
                        <span class="timeline-icon">
                          <i class="bi 
                            {{ livraison.status < step.status ? 'bi-clock-history' : '' }}
                            {{ livraison.status === step.status ? 'bi bi-hourglass-split' : '' }}
                            {{ livraison.status > step.status ? 'bi-check-lg' : '' }}
                            {{ (step.status === 6 && livraison.status > 0 ||
                                (step.status === livraison.status) && [3, 4].includes(step.status)) ? 'bi-check-lg' : '' }} 
                            m-0"></i>
                        </span>
                        <div class="p-3">
                          <p class="h4 m-0 mb-2 text-center"><i class="bi {{step.icon}}"></i></p>
                          <h6 class="m-0 text-center">{{step.name}}</h6>
                        </div>
                      </div>
                    </ng-container>

                  </ng-container>
                </section>

                <div *ngIf="livraison.status === 5" class="mt-3 p-2 h3 rounded-3 alert alert-danger text-center" role="alert">
                  ANNULÉE
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <br>
    
      <ng-container *ngIf="isLivraisonReceived && isPalettesReceived && isCartonsReceived && isProductsReceived">
        <app-scan-palettes [token]="token" [livraison]="livraison" [palettes]="palettes" [orderPalettes]="orderPalettes" (updatedLivraison)="updateLivraison($event)"></app-scan-palettes>
      </ng-container>
      
      <div *ngIf="errorHandled">
        <div class="card text-white bg-danger mb-3 mx-auto" style="max-width: 18rem;">
          <div class="card-header">Erreur</div>
          <div class="card-body">
            <h5 class="card-title">{{ errorHandled.id }}</h5>
            <p class="card-text">
              {{ errorHandled.message }}
            </p>
          </div>
        </div>
      </div>
    
      <div *ngIf="error">
        <ng-container *ngIf="isAdmin">
          <br>
          <pre><code>{{ error | json }}</code></pre>
        </ng-container>
      </div>
    
      <canvas id="barcode" style="visibility:hidden; display: none;"></canvas>
    </div>

    <ng-template #validateDevisConfirmModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="validateDevisConfirmModal-title">CONFIRMATION</h4>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <p class="fw-bolder">Valider le devis de transport ?</p>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger fw-bolder" ngbAutofocus (click)="modal.dismiss()">Annuler</button>
        <button type="button" class="btn btn-success fw-bolder" (click)="modal.close(); validateDevis()">
          <i class="bi bi-check2-square"></i>
          Valider et passer à l'étape suivante
        </button>
      </div>
    </ng-template>

    <ng-template #emailPreviewModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="emailPreviewModal-title">{{ emailPreviewModalTitle }}</h4>
        
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <p>Vérifiez-les informations ci-dessous.</p>

            <app-livraison-details-email-preview [emailContent]="emailPreviewModalContent"></app-livraison-details-email-preview>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="flex-grow-1">
          <ng-container *ngIf="currentEmailPreview === 'devis_transport' && livraison.provider?.id === 3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexFirstDelivery3" [checked]="isFirstDelivery" (change)="onChangeDevisTransportEmailType($event, infoModal, emailPreviewModal)">
              <label class="form-check-label" for="flexFirstDelivery3">Première livraison
                <ng-container *ngIf="livraison.provider?.id === 3">(nécessaire pour l'étape intermédiare Haute Lande si première livraison)</ng-container>
              </label>
            </div>
          </ng-container>
          <ng-container *ngIf="currentEmailPreview === 'demande_livraison_franchise'">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexFirstDelivery1" [checked]="isFirstDelivery" (change)="onChangeDemandeLivraisonFranchiseType($event, infoModal, emailPreviewModal)">
              <label class="form-check-label" for="flexFirstDelivery1">Première livraison</label>
            </div>
          </ng-container>
          <ng-container *ngIf="currentEmailPreview === 'demande_attribution_palettes'">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexFirstDelivery2" [checked]="isFirstDelivery" (change)="onChangeDemandeAttributionPaletteType($event, infoModal, emailPreviewModal)">
              <label class="form-check-label" for="flexFirstDelivery2">Première livraison
                <ng-container *ngIf="livraison.provider?.id !== 3">(inclus dans l'objet du mail)<br><b>Supprimera vos modifications, vous devrez les resaisir.</b></ng-container>
                <ng-container *ngIf="livraison.provider?.id === 3">(nécessaire pour l'étape intermédiare Haute Lande si première livraison)</ng-container>
              </label>
            </div>
          </ng-container>
        </div>
        
        <button type="button" class="btn btn-outline-danger fw-bolder" ngbAutofocus (click)="modal.dismiss()">ANNULER</button>
        <button type="button" class="btn btn-success fw-bolder" (click)="sendEmail(infoModal, modal)">ENVOYER</button>
      </div>
    </ng-template>

    <ng-template #infoModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="infoModal-title">{{ titleInfoModal }}</h4>
        
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3" [innerHTML]="textInfoModal">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary fw-bolder" ngbAutofocus (click)="modal.close();">OK</button>
      </div>
    </ng-template>

    <ng-template #cancelLivraisonConfirmModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="cancelLivraisonConfirmModal-title">CONFIRMATION</h4>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <p class="fw-bolder">Souhaitez-vous VRAIMENT <span class="text-danger">ANNULER CETTE LIVRAISON ?</span></p>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary fw-bolder" ngbAutofocus (click)="modal.dismiss()">NON, SORTIR</button>
        <button type="button" class="btn btn-danger fw-bolder" (click)="modal.close(); cancelLivraison(infoModal)">
          <i class="bi bi-exclamation-triangle-fill"></i>
          <i class="bi bi-exclamation-triangle-fill"></i>
          ANNULER LA LIVRAISON
          <i class="bi bi-exclamation-triangle-fill"></i>
          <i class="bi bi-exclamation-triangle-fill"></i>
        </button>
      </div>
    </ng-template>

  </main>
  