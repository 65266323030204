import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Subject, Observable, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LivraisonService {

  initialLivraisons: [] = [];
  livraisons: [] = [];
  livraisonsSubject = new Subject();
  documentsSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getLivraisons(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log(headers)
    return this.http.get<any[]>(environment.BASE_URL + '/api/livraisons', { headers });
  }
  
  searchLastLivraison(franchise: any, searchDate: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/last/${franchise}/${searchDate}`, { headers });
  }
  
  getCheckedDocuments(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/${id}/checkedDocuments`, { headers });
  }

  getLivraisonsHistory(page: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log(headers)
    return this.http.get<any[]>(environment.BASE_URL + '/api/livraisons/history/' + page, { headers });
  }

  getFranchiseLivraisonsHistory(franchiseId: number, page: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/franchise/' + franchiseId + '/livraisons/' + page, { headers });
  }

  addLivraison(data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/livraison', data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  createCheckedDocuments(idLivraison: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${idLivraison}/checkedDocuments`, {}, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateAdresseLivraison(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison/${id}/adresse`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  updateLivraison(id: number, data: any, token: string) {
    data.palettes = []; // TODO A revoir plus tard dans livraison-details pourquoi les Palettes sont remplies dans l'array
    console.log('updateLivraison => ', data);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison/${id}`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  updateCheckedDocumentsLivraison(id: number, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison/${id}/checkedDocuments`, data, { headers })
      .pipe(
        map((res: any) => {
          this.documentsSubject.next(res);
          return res;
        })
      );
  }

  getLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log("HEADERS get livraison", id)
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}`, { headers });
  }

  getLivraisonPalettesOrdered(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/${id}/order/palettes`, { headers });
  }

  putOrderPalettes(id: string, data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/livraison/${id}/order/palettes/${data.modelePalette}`, data, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  deleteOrderPalettes(id: number, modelePalette: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/livraison/${id}/order/palettes/${modelePalette}`, { headers })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getPalettesFromLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/${id}/palettes`, { headers });
  }

  getCartonsFromLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/${id}/cartons`, { headers });
  }

  getProductsFromLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + `/api/livraison/${id}/products`, { headers });
  }

  getDocument(initiales: string, id: string, type: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log("TYPE", type);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/document/${type}/${initiales}`, { headers });
  }

  addDocument(data: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/document`, data, { headers })
      .pipe(
        map((res: any) => {
          this.livraisonsSubject.next(res);
          return res;
        })
      );
  }

  createBonLivraison(livraison: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    console.log("LIVRAISON BON SERVICE STRINGIFY", JSON.stringify(livraison.palettes, null, 2))
    console.log("LIVRAISON BON SERVICE", livraison)
    return this.http.post(environment.BASE_URL + `/api/livraison/bonLivraison`, livraison, { headers });
  }

  createBonDetails(livraison: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/bonDetails`, livraison, { headers });
  }

  createBonTransport(livraison: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/bonTransport`, livraison, { headers });
  }

  createCG(livraison: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/cg`, livraison, { headers });
  }

  getDevisTransportLivraisonPreview(id: number, token: string, emailType: any) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/devisTransport/preview` + (emailType ? (`?emailType=${emailType}`) : ""), { headers });
  }

  sendDevisTransportLivraison(id: number, previewEdits: object, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${id}/devisTransport`, { previewEdits }, { headers });
  }

  getDemandeLivraisonFranchisePreview(id: number, emailType: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/demandeLivraisonFranchise/preview` + (emailType ? (`?emailType=${emailType}`) : ""), { headers });
  }

  sendDemandeLivraisonFranchise(id: number, emailType: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${id}/demandeLivraisonFranchise`, { emailType }, { headers });
  }

  getDemandeAttributionPalettesPreview(id: number, emailType: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/demandeAttributionPalettes/preview` + (emailType ? (`?emailType=${emailType}`) : ""), { headers });
  }

  sendDemandeAttributionPalettes(id: number, previewEdits: object, emailType: string, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${id}/demandeAttributionPalettes`, { previewEdits, emailType }, { headers });
  }

  getValidationLivraisonTransportPreview(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/validationLivraisonTransport/preview`, { headers });
  }

  sendValidationLivraisonTransport(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${id}/validationLivraisonTransport`, {}, { headers });
  }

  getLivraisonValideeFranchisePreview(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/livraisonValideeFranchise/preview`, { headers });
  }

  sendLivraisonValideeFranchise(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + `/api/livraison/${id}/livraisonValideeFranchise`, {}, { headers });
  }

  getLivraisonTasks(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/livraison/${id}/tasks`, { headers });
  }

  deleteLivraison(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.delete(environment.BASE_URL + `/api/livraison/${id}`, { headers });
  }
}
