import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProviderService } from '../services/provider.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-providers-add-form',
  templateUrl: './providers-add-form.component.html',
  styleUrls: ['./providers-add-form.component.css']
})
export class ProvidersAddFormComponent {
  token: string = '';
  decodedToken: any = {};

  error: any = '';

  constructor(private providerService: ProviderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if (this.authService.userIsLoggedIn()) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);

      if (!this.decodedToken || this.decodedToken?.role.nom !== 'admin') {
        this.router.navigateByUrl('/');
      }
    }
  }

  onSubmitProvider(provider: any) {
    if (provider.nom === null || provider.nom.trim().length === 0) {
      this.error = true;
      return;
    }

    const token = JSON.parse(localStorage.getItem('bcp-token') || '').token;

    this.providerService.createProvider(provider, token).subscribe(
      {
        next: data => this.handleProviderCreation(data),
        error: err => console.error(err),
        complete: () => console.log('provider was added!')
      }
    )
  }

  handleProviderCreation(data: any) {
    if (!data ||!data.success) {
      
    } else {
      this.router.navigateByUrl('/provider/' + data.provider.id);
    }
  }
}
