<nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" routerLink="/">{{ title }}</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav me-auto">
        <!-- <li class="nav-item active">
          <a class="nav-link" href="#">Home <span class="visually-hidden">(current)</span></a> -->
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isCentre || isAdmin)">
          <a href="#" class="nav-link" routerLink="/cartons">Cartons</a>
        </li>
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isCentre || isAdmin)">
          <a href="#" class="nav-link" routerLink="/palettes">Palettes</a>
        </li>
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isCentre || isAdmin)">
          <a href="#" class="nav-link" routerLink="/livraisons">Livraisons</a>
        </li>
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && isAdmin">
          <a href="#" class="nav-link" routerLink="/livraisons-asi">Livraisons ASI</a>
        </li>
        <!--<li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isAdmin && decodedToken.username === 'jcarrat') ">
          <a href="#" class="nav-link" routerLink="/demandes-livraisons">Demandes de livraison</a>
        </li>-->
        <!--<li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isFranchise || isAdmin)">
          <a href="#" class="nav-link" routerLink="/inventaire">Inventaire</a>
        </li>-->
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isAdmin || (isCentre && (provider.id === 1 || provider.id === 2)) )">
          <a href="#" class="nav-link" routerLink="/retours">Retours</a>
        </li>
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isAdmin)">
          <a href="#" class="nav-link" routerLink="/franchises">Franchisés</a>
        </li>
        <!--<li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isAdmin)">
          <a href="#" class="nav-link" routerLink="/providers">Centres de retraitement</a>
        </li>-->
        <!-- <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isCentre || isAdmin)">
          <a href="#" class="nav-link" routerLink="/fournitures">Fournitures</a>
        </li> -->
        <!-- <li class="nav-item" *ngIf="authService.userIsLoggedIn()">
          <a href="#" class="nav-link" routerLink="/about">À propos</a>
        </li> -->
        <!-- <<li class="nav-item" *ngIf="authService.userIsLoggedIn()">
          <a href="#" class="nav-link" routerLink="/profile">Profil</a>
        </li> -->
        <li class="nav-item" *ngIf="authService.userIsLoggedIn() && (isCentre && !isAdmin)">
          <a href="#" class="nav-link" routerLink="/stats">Statistiques</a>
        </li>
        <li class="nav-item" *ngIf="authService.userIsLoggedIn()">
          <a href="#" class="nav-link" (click)="logout()">Se déconnecter</a>
        </li>
        <!--<li class="nav-item" *ngIf="!authService.userIsLoggedIn()">
          <a href="#" class="nav-link" routerLink="/register">S'enregistrer</a>
        </li>-->
      </ul>
    </div>
  </div>

  <ul class="navbar-nav ">
    <li *ngIf="authService.userIsLoggedIn()" class="nav-item">
      <div id="welcome" *ngIf="decodedToken">
        Bienvenue <b>{{decodedToken.username}}</b>
      </div>
    </li>
  </ul>
</nav>
<br/>