import { Component } from '@angular/core';
import { Provider } from '../model/provider';
import { Router } from '@angular/router';
import { ProviderService } from '../services/provider.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-providers-list',
  templateUrl: './providers-list.component.html',
  styleUrls: ['./providers-list.component.css']
})
export class ProvidersListComponent {

  token: string = '';
  decodedToken: any = {};
  isAdmin: boolean = false;
  providers: Provider[] = [];
  error: string = '';
  userIsLoggedin: boolean = false;
  errorMessage: string = '';

  constructor(private router: Router, private providerService: ProviderService, private authService: AuthService) { }

  ngOnInit() {
    this.checkUserIsLoggedin();

    if (this.userIsLoggedin) {
      const bcpToken = JSON.parse(localStorage.getItem('bcp-token') || '');
      this.token = bcpToken.token;
      this.decodedToken = this.authService.decodeToken(this.token);

      if (this.decodedToken && this.decodedToken.role.nom === 'admin') {
        this.isAdmin = true;
      }

      if (this.isAdmin) {
        this.loadProviders();
      } else {
        this.router.navigateByUrl("/");
      }
    }
  }

  checkUserIsLoggedin() {
    if (this.authService.userIsLoggedIn()) {
      this.userIsLoggedin = true;
    }
  }

  loadProviders() {
    this.providerService.getProviders(this.token)
      .subscribe({
        next: data => this.displayProviders(data),
        error: err => console.error(err),
        complete: () => console.log('providers loaded!')
      })
  }

  displayProviders(response: any = {}) {
    if (response.success) {
      this.providers = response.providers;
      console.log("THIS PROVIDERS", this.providers)
    } else {
      this.errorMessage = response.message;
      console.log(this.errorMessage);
    }
  }

  createProvider() {
    this.router.navigateByUrl(`/provider/create`);
  }
}
