import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GlobalRetour } from '../model/globalRetour';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductRetour } from '../model/product_retour';

@Injectable({
  providedIn: 'root'
})
export class GlobalRetourService {

  initialRetours = [];
  retours = [];
  retoursSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getGlobalRetours(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    // console.log('token', token)
    // console.log("HEADERS", headers)
    // return this.http.get<any[]>(environment.BASE_URL + `/api/global-retours/`, { headers });
    return this.http.get<any[]>(environment.BASE_URL + `/api/retours/`, { headers });
  }

  getGlobalRetoursHistory(token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    // return this.http.get<any[]>(environment.BASE_URL + `/api/global-retours/history`, { headers });
    return this.http.get<any[]>(environment.BASE_URL + `/api/retours/history`, { headers });
  }

  addGlobalRetour(data: GlobalRetour, token: string) {
    // console.log("orderData => ", orderData);
    const headers = this.authService.addAuthorizationHeader(token);
    // console.log("authservice in service", this.authService)
    // return this.http.post(environment.BASE_URL + `/api/global-retour/`, data, { headers })
    return this.http.post(environment.BASE_URL + `/api/retour/`, data, { headers })
      .pipe(
        map((res: any) => {
          // console.log("res", res);
          this.retoursSubject.next(res);
          return res;
        })
      );

  }

  addProductRetourFranchise(id: any, source: any, data: ProductRetour, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);

    return this.http.post(environment.BASE_URL + `/api/retour/${id}/source/${source}/product`, data, {headers}).subscribe(
      data => console.log('success', data),
      error => console.log('oops', error)
    );
  }

  updateGlobalRetour(data: GlobalRetour, token: string) {
    // console.log('updateGlobalRetour => ', data);
    const headers = this.authService.addAuthorizationHeader(token);
    // return this.http.put(environment.BASE_URL + `/api/global-retour/${data.id}`, data, { headers })
    return this.http.put(environment.BASE_URL + `/api/retour/${data.id}`, data, { headers })
      .pipe(
        map((res: any) => {
          // console.log(res);
          this.retoursSubject.next(res);
          return res;
        })
      );

  }

  getGlobalRetour(id: any, token: string) {
    // console.log('getGlobalRetour ID : ', id);
    const headers = this.authService.addAuthorizationHeader(token);
    // return this.http.get(environment.BASE_URL + `/api/global-retour/${id}`, { headers });
    return this.http.get(environment.BASE_URL + `/api/retour/${id}`, { headers });
  }

  getRetourSource(id: any, source: any, token: string) {
    // console.log(`getRetourSource ID : ${id} | SOURCE : ${source}`);
    const headers = this.authService.addAuthorizationHeader(token);
    // return this.http.get<any[]>(environment.BASE_URL + `/api/global-retour/${id}/${source}`, { headers });
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}`, { headers });
  }

  getCartonsFromGlobalRetour(id: any, source: any, token: string) {
    // console.log(`getCartonsFromGlobalRetour ID : ${id} | SOURCE : ${source}`);
    const headers = this.authService.addAuthorizationHeader(token);
    // return this.http.get<any[]>(environment.BASE_URL + `/api/global-retour/${id}/${source}/cartons`, { headers });
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}/cartons`, { headers });
  }

  getProductsFromGlobalRetour(id: any, source: any, token: string) {
    // console.log(`getProductsFromGlobalRetour ID : ${id} | SOURCE : ${source}`);
    const headers = this.authService.addAuthorizationHeader(token);
    // console.log("token getproduct", token)
    // console.log("HEADERS getproduct", headers)
    // return this.http.get<any[]>(environment.BASE_URL + `/api/global-retour/${id}/${source}/products`, { headers });
    return this.http.get<any[]>(environment.BASE_URL + `/api/retour/${id}/source/${source}/products`, { headers });
  }
}
