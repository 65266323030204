import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import { environment } from '../../environments/environment';
import {Subject} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  initialProviders = [];
  providers = [];
  providersSubject = new Subject();
  searchResultSubject = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) { }

  getProviders(token: string) {
    // console.log('getProviders');
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get<any[]>(environment.BASE_URL + '/api/providers', { headers });
  }

  createProvider(provider: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/provider', provider, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }

  getProvider(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${id}`, { headers });
  }

  getProviderAddresses(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${id}/adresses`, { headers });
  }

  updateProvider(id: number, providerData: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/provider/${id}`, providerData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }

  addAddress(provider: any, addressData: any, token: string) {
    console.log("addAddress => ", addressData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/provider/' + provider + '/adresse', addressData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }

  getAddress(idProvider: number, idAddress: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${idProvider}/adresse/${idAddress}`, { headers });
  }

  updateAddress(idProvider: number, idAddress: number, address: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/provider/${idProvider}/adresse/${idAddress}`, address, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }

  getProviderContacts(id: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${id}/contacts`, { headers });
  }

  addContact(provider: any, contactData: any, token: string) {
    console.log("addContact => ", contactData);
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.post(environment.BASE_URL + '/api/provider/' + provider + '/contact', contactData, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }

  getContact(idProvider: number, idContact: number, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.get(environment.BASE_URL + `/api/provider/${idProvider}/contact/${idContact}`, { headers });
  }

  updateContact(idProvider: number, idContact: number, contact: any, token: string) {
    const headers = this.authService.addAuthorizationHeader(token);
    return this.http.put(environment.BASE_URL + `/api/provider/${idProvider}/contact/${idContact}`, contact, { headers })
      .pipe(
        map((res: any) => {
          console.log("res ", res);
          this.providersSubject.next(res);
          return res;
        })
      );
  }
}
