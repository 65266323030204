import { Component, Input } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-livraison-details-email-preview',
  templateUrl: './livraison-details-email-preview.component.html',
  styleUrls: ['./livraison-details-email-preview.component.css']
})
export class LivraisonDetailsEmailPreviewComponent {
  @Input() emailContent: SafeHtml = "";
}
