<div class="container mb-5">

    <main role="main" class="container">
        <ng-container *ngIf="!franchise_deja_cree">
          <app-franchise-add-form></app-franchise-add-form>
        </ng-container>
      
        <ng-container *ngIf="franchise_deja_cree">
          <div class="primary-block p-3 shadow rounded-3">
            <div class="container">
              <div class="d-flex">
                <h2 class="flex-grow-1 m-0">{{ franchise.facturationNom }}</h2>
                <a href="#" routerLink="livraisons" class="btn btn-warning ms-2"><i class="bi bi-clock-history me-2"></i>Historique des livraisons</a>
              </div>
              <div *ngIf="franchise_updated" class="alert alert-success">{{ franchise_updated }}</div>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div class="card m-1">
                    <h4 class="card-header">Franchisé</h4>
                    <div class="card-body"> 
                      <div class="card-text">
                        <h6 class="mb-4">Créé le {{ franchise.dateCreation | date: 'dd-MM-YYYY' }}</h6>
                        <form #formUpdateFranchise="ngForm" (ngSubmit)="onUpdateFranchise(franchise_id, formUpdateFranchise.value)">
                          <div class="input-group mb-2">
                            <span class="input-group-text">Nom</span>
                            <input type="text" class="form-control" [(ngModel)]="franchise.nomPart" id="nom" name="nom" placeholder="Nom du franchisé..." aria-label="nom">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Prénom</span>
                            <input type="text" class="form-control" [(ngModel)]="franchise.prenomPart" id="prenom" name="prenom" placeholder="Prénom du franchisé..." aria-label="prenom">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Initiales</span>
                            <input type="text" class="form-control" [(ngModel)]="franchise.initiales" id="initiales" name="initiales" placeholder="Initiales du franchisé..." aria-label="initiales">
                          </div>
                          <ng-container *ngIf="franchise.email; else nomail">
                            <div class="input-group mb-2" >
                              <span class="input-group-text">Email</span>
                              <input type="text" class="form-control" [(ngModel)]="franchise.email" id="email" name="email" placeholder="Email du franchisé..." aria-label="email">                            
                            </div>
                          </ng-container>
                          <ng-template #nomail>
                            <div class="input-group mb-2" >
                              <span class="input-group-text">Email</span>
                              <input type="text" class="form-control" id="email" name="email" placeholder="Email du franchisé..." ngModel aria-label="email">                            
                            </div>
                          </ng-template>
                          <div class="input-group mb-3" >
                            <span class="input-group-text">Téléphone</span>
                            <input type="text" class="form-control" [(ngModel)]="franchise.TelPortPart" id="phone" name="phone" placeholder="Téléphone du franchisé..." ngModel aria-label="phone">
                          </div>
                          <div class="d-flex justify-content-end bd-highlight">
                            <button appNoDblClick (click)="enableOrDisableFranchise()" class="btn btn-success" type="button" *ngIf="!franchise.isActif">
                              <i class="bi bi-check-circle me-2"></i>Activer le Franchisé
                            </button>
                            <button appNoDblClick (click)="enableOrDisableFranchise()" class="btn btn-danger" type="button" *ngIf="franchise.isActif">
                              <i class="bi bi-dash-circle me-2"></i>Désactiver le Franchisé
                            </button>
                            <button class="btn btn-warning ms-2" type="submit" value="submit">Valider les modifications</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card m-1" *ngIf="formNewAdresseFacturation">
                    <h4 class="card-header">Nouvelle adresse de facturation</h4>
                    <div class="card-body">
                      <div class="card-text">
                        <form #formAddSocieteAdresseFacturation="ngForm" (ngSubmit)="addSocieteAdresseFacturation(formAddSocieteAdresseFacturation.value)">
                          <div class="input-group mb-2">
                            <span class="input-group-text">Forme juridique</span>
                            <input type="text" class="form-control" ngModel id="forme_juridique" name="forme_juridique" placeholder="Forme juridique de la société..." aria-label="forme_juridique">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Abréviation juridique</span>
                            <input type="text" class="form-control" ngModel id="abreviation_juridique" name="abreviation_juridique" placeholder="Abréviation juridique de la société..." aria-label="abreviation_juridique">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Adresse</span>
                            <input type="text" class="form-control" ngModel id="adress" name="adresse" placeholder="Adresse de la société..." aria-label="adresse">
                          </div>
                          <div class="input-group mb-2" >
                            <span class="input-group-text">Complément d'adresse</span>
                            <input type="text" class="form-control" id="complement_adresse" name="complement_adresse" placeholder="Complément d'adresse de la société..." ngModel aria-label="complement_adresse">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Code postal</span>
                            <input type="text" class="form-control" ngModel id="code_postal" name="code_postal" placeholder="Code postal de la société..." aria-label="code_postal">
                            <span class="input-group-text">Ville</span>
                            <input type="text" class="form-control" ngModel id="ville" name="ville" placeholder="Ville de la société..." aria-label="ville">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">TVA Intracom</span>
                            <input type="text" class="form-control" ngModel id="TVA_intracom" name="TVA_intracom" placeholder="TVA Intracom de la société..." aria-label="TVA_intracom">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">SIREN</span>
                            <input type="text" class="form-control" ngModel id="siren" name="siren" placeholder="SIREN de la société..." aria-label="siren">
                            <span class="input-group-text">Complément SIRET</span>
                            <input type="text" class="form-control" ngModel id="complement_siren" name="complement_siren" placeholder="Complément SIRET de la société..." aria-label="complement_siren">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">NAF</span>
                            <input type="text" class="form-control" ngModel id="naf" name="naf" placeholder="NAF de la société..." aria-label="naf">
                          </div>
                          <div class="input-group mb-3">
                            <span class="input-group-text">Capital</span>
                            <input type="text" class="form-control" ngModel id="capital" name="capital" placeholder="Capital..." aria-label="capital">
                            <span class="input-group-text">Ville RCS</span>
                            <input type="text" class="form-control" ngModel id="ville_RCS" name="ville_RCS" placeholder="Ville RCS de la société..." aria-label="ville_RCS">
                          </div>
                          <div class="d-flex flex-row-reverse bd-highlight">
                            <button class="btn btn-success" type="submit" value="submit">Valider la création</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="card m-1">
                    <h4 class="card-header">Société - Adresse de facturation</h4>
                    <div class="card-body">
                      <div *ngIf="facturation_updated" class="alert alert-success alert-dismissible fade show">
                        {{ facturation_updated }}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      <label class="form-label">Liste des adresses de facturation</label>
                      <select [(ngModel)]="selectedLivraisonAddress" (ngModelChange)="onChangeFacturationAdresse($event)" name="facturationSelect" id="facturationSelect" class="form-select mb-4">
                        <optgroup label="Adresses actives">
                          <option *ngFor="let f of facturationActiveAdresses" [ngValue]="f.id">
                            {{ f.id }} - {{ f.adresse }}, {{ f.code_postale }} {{ f.ville }}
                          </option>
                        </optgroup>
                        <optgroup label="Adresses inactives">
                          <option *ngFor="let f of facturationInactiveAdresses" [ngValue]="f.id">
                              {{ f.id }} - {{ f.adresse }}, {{ f.code_postale }} {{ f.ville }}
                          </option>
                        </optgroup>
                      </select> 
                      <div class="card-text">
                        <form #formUpdateSocieteAdresseFacturation="ngForm" (ngSubmit)="updateSocieteAdresseFacturation(selectedFacturationAddress.id, formUpdateSocieteAdresseFacturation.value)">
                          <div class="input-group mb-2">
                            <span class="input-group-text">Forme juridique</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.forme_juridique" id="form_jur" name="form_jur" placeholder="Forme juridique de la société..." aria-label="form_jur">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Abréviation juridique</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.abreviation_juridique" id="abr_jur" name="abr_jur" placeholder="Abréviation juridique de la société..." aria-label="abr_jur">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Adresse</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.adresse" id="address" name="address" placeholder="Adresse de la société..." aria-label="address">
                          </div>
                          <ng-container *ngIf="franchise.facturationCompAdresse; else nocomp">
                            <div class="input-group mb-2">
                              <span class="input-group-text">Complément d'adresse</span>
                              <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.complement_adresse" id="comp_address" name="comp_address" placeholder="Complément d'adresse de la société..." aria-label="comp_address">
                            </div>
                          </ng-container>
                          <ng-template #nocomp>
                            <div class="input-group mb-2" >
                              <span class="input-group-text">Complément d'adresse</span>
                              <input type="text" class="form-control" id="comp_address" name="comp_address" placeholder="Complément d'adresse de la société..." ngModel aria-label="comp_address">
                            </div>
                          </ng-template>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Code postal</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.code_postale" id="code_postal" name="code_postal" placeholder="Code postal de la société..." aria-label="code_postal">
                            <span class="input-group-text">Ville</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.ville" id="ville" name="ville" placeholder="Ville de la société..." aria-label="ville">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">TVA Intracom</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.TVA_intracom" id="TVA_intracom" name="TVA_intracom" placeholder="TVA Intracom de la société..." aria-label="TVA_intracom">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">SIREN</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.siren" id="siren" name="siren" placeholder="SIREN de la société..." aria-label="siren">
                            <span class="input-group-text">Complément SIRET</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.complement_siren" id="comp_siren" name="comp_siren" placeholder="Complément SIRET de la société..." aria-label="comp_siren">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">NAF</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.naf" id="naf" name="naf" placeholder="NAF de la société..." aria-label="naf">
                          </div>
                          <div class="input-group mb-3">
                            <span class="input-group-text">Capital</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.capital" id="capital" name="capital" placeholder="Capital..." aria-label="capital">
                            <span class="input-group-text">Ville RCS</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedFacturationAddress.ville_RCS" id="ville_RCS" name="ville_RCS" placeholder="Ville RCS de la société..." aria-label="ville_RCS">
                          </div>
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="selectedFacturationAddress.isActif" id="flexSwitchCheckDefault" id="is_actif" name="is_actif" aria-label="is_actif" (change)="onChangeFacturationActive($event)">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Adresse active ?</label>
                          </div>
                          <div class="d-flex flex-row-reverse bd-highlight mb-2" *ngIf="!formNewAdresseFacturation">
                            <button class="btn btn-warning" type="submit" value="submit">Valider les modifications</button>
                          </div>
                        </form>
                        <div class="d-flex flex-row-reverse bd-highlight" *ngIf="!formNewAdresseFacturation">
                          <button class="btn btn-primary" type="submit" value="submit" (click)="newAdresseFacturation($event)" (change)="newAdresseFacturation($event)">Créer une nouvelle adresse de facturation</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="card m-1" *ngIf="formNewAdresseLivraison">
                    <h4 class="card-header">Nouvelle adresse de livraison</h4>
                    <div class="card-body">
                      <div class="card-text">
                        <form #formAddSocieteAdresseLivraison="ngForm" (ngSubmit)="addSocieteAdresseLivraison(formAddSocieteAdresseLivraison.value)">
                          <div class="input-group mb-2">
                            <span class="input-group-text">Type de livraison</span>
                          <select class="form-select" aria-label="Type d'adresse" ngModel id="type_adresse" name="type_adresse">
                            <option value="1">Livraison matériel palette support</option>
                            <option value="2">Livraison brochures, etc.</option>
                          </select>
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Adresse</span>
                            <input type="text" class="form-control" ngModel id="adress" name="adresse" placeholder="Adresse de la société..." aria-label="adresse">
                          </div>
                          <div class="input-group mb-2" >
                            <span class="input-group-text">Complément d'adresse</span>
                            <input type="text" class="form-control" id="complement_adresse" name="complement_adresse" placeholder="Complément d'adresse de la société..." ngModel aria-label="complement_adresse">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Code postal</span>
                            <input type="text" class="form-control" ngModel id="code_postal" name="code_postal" placeholder="Code postal de la société..." aria-label="code_postal">
                            <span class="input-group-text">Ville</span>
                            <input type="text" class="form-control" ngModel id="ville" name="ville" placeholder="Ville de la société..." aria-label="ville">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Latitude</span>
                            <input type="number" class="form-control" ngModel id="latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00000001" min="-90" max="90">
                            <span class="input-group-text">Longitude</span>
                            <input type="number" class="form-control" ngModel id="longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00000001" min="-180" max="180">
                          </div>
                          <div class="input-group mb-2">
                            <label class="input-group-text" for="accessibilite_19T">Accessibilité 19T ?</label>
                            <select id="accessibilite_19T" name="accessibilite_19T" class="form-select" ngModel placeholder="Accessibilité 19T?">
                              <option value=1>Oui</option>
                              <option value=0>Non</option>
                            </select>
                          </div>
                          <div class="d-flex flex-row-reverse bd-highlight">
                            <button class="btn btn-success" type="submit" value="submit">Valider la création</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="card m-1">
                    <h4 class="card-header">Société - Adresse de livraison</h4>
                    <div class="card-body">
                      <div *ngIf="livraison_updated" class="alert alert-success alert-dismissible fade show">
                        {{ livraison_updated }}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      <div class="card-text">
                        <label class="form-label">Liste des adresses de livraison</label>
                        <select [(ngModel)]="selectedLivraisonAddress" (ngModelChange)="onChangeLivraisonAdresse($event)" name="livraisonSelect" id="livraisonSelect" class="form-select mb-4">
                          <optgroup label="Adresses actives">
                            <option *ngFor="let f of livraisonActiveAdresses" [ngValue]="f.id">
                              {{ f.id }} - {{ f.adresse }}, {{ f.code_postale }} {{ f.ville }}
                            </option>
                          </optgroup>
                          <optgroup label="Adresses inactives">
                            <option *ngFor="let f of livraisonInactiveAdresses" [ngValue]="f.id">
                                {{ f.id }} - {{ f.adresse }}, {{ f.code_postale }} {{ f.ville }}
                            </option>
                          </optgroup>
                        </select>
                        <form #formUpdateSocieteAdresseLivraison="ngForm" (ngSubmit)="updateSocieteAdresseLivraison(selectedLivraisonAddress.id, formUpdateSocieteAdresseLivraison.value)">
                          <div class="input-group mb-2">
                            <span class="input-group-text">Type de livraison</span>
                            <select class="form-select" aria-label="Type d'adresse" name="type_adresse" id="type_adresse" [(ngModel)]="selectedLivraisonAddress.type_adresse">
                              <option value=1>Livraison matériel palette support</option>
                              <option value=2>Livraison brochures, etc.</option>
                            </select>
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Adresse</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedLivraisonAddress.adresse" id="address" name="address" placeholder="Adresse de la société..." aria-label="address">
                          </div>
                          <ng-container *ngIf="franchise.livraisonCompAdresse; else nocomp">
                            <div class="input-group mb-2">
                              <span class="input-group-text">Complément d'adresse</span>
                              <input type="text" class="form-control" [(ngModel)]="selectedLivraisonAddress.complement_adresse" id="comp_address" name="comp_address" placeholder="Complément d'adresse de la société..." aria-label="comp_address">
                            </div>
                          </ng-container>
                          <ng-template #nocomp>
                            <div class="input-group mb-2" >
                              <span class="input-group-text">Complément d'adresse</span>
                              <input type="text" class="form-control" id="comp_address" name="comp_address" placeholder="Complément d'adresse de la société..." ngModel aria-label="comp_address">
                            </div>
                          </ng-template>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Code postal</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedLivraisonAddress.code_postale" id="code_postal" name="code_postal" placeholder="Code postal de la société..." aria-label="code_postal">
                            <span class="input-group-text">Ville</span>
                            <input type="text" class="form-control" [(ngModel)]="selectedLivraisonAddress.ville" id="ville" name="ville" placeholder="Ville de la société..." aria-label="ville">
                          </div>
                          <div class="input-group mb-2">
                            <span class="input-group-text">Latitude</span>
                            <input type="number" class="form-control" [(ngModel)]="selectedLivraisonAddress.latitude" id="latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00000001" min="-90" max="90">
                            <span class="input-group-text">Longitude</span>
                            <input type="number" class="form-control" [(ngModel)]="selectedLivraisonAddress.longitude" id="longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00000001" min="-180" max="180">
                          </div>
                          <div class="input-group mb-2">
                            <label class="input-group-text" for="t19">Accessibilité 19T ?</label>
                            <select id="t19" name="t19" class="form-select" [(ngModel)]="franchise.livraison19T" [disabled]="selectedLivraisonAddress.type_adresse === 2 || selectedLivraisonAddress.type_adresse === '2'" placeholder="Accessibilité 19T?">
                              <option value=1>Oui</option>
                              <option value=0>Non</option>
                            </select>
                          </div>
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="selectedLivraisonAddress.isActif" id="flexSwitchCheckDefault" id="is_actif" name="is_actif" aria-label="is_actif" (change)="onChangeFacturationActive($event)">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Adresse active ?</label>
                          </div>
                          <div class="d-flex flex-row-reverse bd-highlight mb-2" *ngIf="!formNewAdresseLivraison">
                            <button class="btn btn-warning" type="submit" value="submit">Valider les modifications</button>
                          </div>
                        </form>
                        <div class="d-flex flex-row-reverse bd-highlight" *ngIf="!formNewAdresseLivraison">
                          <button class="btn btn-primary" type="submit" value="submit" (click)="newAdresseLivraison($event)" (change)="newAdresseLivraison($event)">Créer une nouvelle adresse de livraison</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
    </main>
  
    <!-- div *ngIf="error">
      <pre>{{ error | json }}</pre>
    </div -->
  </div>
  