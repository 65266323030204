<div class="container mb-5">
  <main role="main" class="container">
    <div class="primary-block p-3 shadow rounded-3">
      <div class="container">
        <h2 *ngIf="originalProvider">{{ originalProvider.nom }}</h2>
        <div *ngIf="provider_updated" class="alert alert-success">{{ provider_updated }}</div>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card m-1">
              <h4 class="card-header">Centre de retraitement</h4>
              <div class="card-body"> 
                <div class="card-text">
                  <form *ngIf="provider" #formUpdateProvider="ngForm" (ngSubmit)="onUpdateProvider(provider.id, formUpdateProvider.value)">
                    <div class="input-group mb-2">
                      <span class="input-group-text">Nom</span>
                      <input type="text" class="form-control" [(ngModel)]="provider.nom" id="nom" name="nom" placeholder="Nom du centre de retraitement..." aria-label="nom">
                    </div>
                    <div class="d-flex justify-content-end bd-highlight mt-2">
                      <button class="btn btn-warning ms-2" type="submit" value="submit">Valider les modifications</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          
          
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card m-1" *ngIf="formNewAddress || addresses.length === 0">
              <h4 class="card-header">Nouvelle adresse</h4>
              <div class="card-body">
                <div class="card-text">
                  <form #formAddAdresse="ngForm" (ngSubmit)="addAddress(formAddAdresse.value)">
                    <div class="input-group mb-2">
                      <span class="input-group-text">Nom</span>
                      <input type="text" class="form-control" ngModel id="new_nom" name="nom" placeholder="Adresse du centre de retraitement..." aria-label="new_nom">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Adresse</span>
                      <input type="text" class="form-control" ngModel id="new_adresse" name="adresse" placeholder="Adresse du centre de retraitement..." aria-label="adresse">
                    </div>
                    <div class="input-group mb-2" >
                      <span class="input-group-text">Complément d'adresse</span>
                      <input type="text" class="form-control" id="new_complement_adresse" name="complement_adresse" placeholder="Complément d'adresse du centre de retraitement..." ngModel aria-label="complement_adresse">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Code postal</span>
                      <input type="text" class="form-control" ngModel id="new_code_postale" name="code_postale" placeholder="Code postal du centre de retraitement..." aria-label="code_postale">
                      <span class="input-group-text">Ville</span>
                      <input type="text" class="form-control" ngModel id="new_ville" name="ville" placeholder="Ville du centre de retraitement..." aria-label="ville">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Latitude</span>
                      <input type="number" class="form-control" ngModel id="new_latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00000001" min="-90" max="90">
                      <span class="input-group-text">Longitude</span>
                      <input type="number" class="form-control" ngModel id="new_longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00000001" min="-180" max="180">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Téléphone</span>
                      <input type="text" class="form-control" ngModel id="new_telephone" name="telephone" placeholder="xx xx xx xx xx" aria-label="new_telephone">
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" ngModel id="new_defaut" name="defaut" aria-label="defaut" [disabled]="addresses.length === 0">
                      <label class="form-check-label" for="new_defaut">Adresse par défaut ?</label>
                    </div>
                    <div class="d-flex flex-row-reverse bd-highlight mt-2">
                      <button class="btn btn-success" type="submit" value="submit">Valider la création</button>
                      <button class="btn btn-danger me-2" type="button" (click)="formNewAddress = false">Annuler</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            <div class="card m-1" *ngIf="!formNewAddress && addresses.length > 0">
              <h4 class="card-header">Adresse</h4>
              <div class="card-body">
                <div *ngIf="address_updated" class="alert alert-success alert-dismissible fade show">
                  {{ address_updated }}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <label class="form-label">Liste des adresses</label>
                <select [ngModel]="selectedAddress?.id ?? 0" (ngModelChange)="onChangeProviderAddress($event)" name="adressSelect" id="adressSelect" class="form-select mb-4">
                  <option *ngFor="let f of addresses" [ngValue]="f.id">
                    {{ f.defaut ? "Par défaut - " : "" }}{{ f.id }} - {{ f.nom }} - {{ f.adresse }}, {{ f.code_postale }} {{ f.ville }}
                  </option>
                </select> 
                <div *ngIf="selectedAddress" class="card-text">
                  <form #formUpdateAddress="ngForm" (ngSubmit)="updateAddress(selectedAddress.id, formUpdateAddress.value)">
                    <div class="input-group mb-2">
                      <span class="input-group-text">Nom</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedAddress.nom" id="nom" name="nom" placeholder="Nom de l'adresse..." aria-label="nom">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Adresse</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedAddress.adresse" id="adresse" name="adresse" placeholder="Adresse du centre de retraitement..." aria-label="adresse">
                    </div>
                    <ng-container *ngIf="selectedAddress.complement_adresse; else nocomp">
                      <div class="input-group mb-2">
                        <span class="input-group-text">Complément d'adresse</span>
                        <input type="text" class="form-control" [(ngModel)]="selectedAddress.complement_adresse" id="complement_adresse" name="complement_adresse" placeholder="Complément d'adresse du centre de retraitement..." aria-label="complement_adresse">
                      </div>
                    </ng-container>
                    <ng-template #nocomp>
                      <div class="input-group mb-2" >
                        <span class="input-group-text">Complément d'adresse</span>
                        <input type="text" class="form-control" id="complement_adresse" name="complement_adresse" placeholder="Complément d'adresse du centre de retraitement..." ngModel aria-label="complement_adresse">
                      </div>
                    </ng-template>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Code postal</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedAddress.code_postale" id="code_postale" name="code_postale" placeholder="Code postal du centre de retraitementé..." aria-label="code_postale">
                      <span class="input-group-text">Ville</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedAddress.ville" id="ville" name="ville" placeholder="Ville du centre de retraitement..." aria-label="ville">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Latitude</span>
                      <input type="number" class="form-control" [(ngModel)]="selectedAddress.latitude" id="latitude" name="latitude" placeholder="Latitude" aria-label="latitude" step="0.00000001" min="-90" max="90">
                      <span class="input-group-text">Longitude</span>
                      <input type="number" class="form-control" [(ngModel)]="selectedAddress.longitude" id="longitude" name="longitude" placeholder="Longitude" aria-label="longitude" step="0.00000001" min="-180" max="180">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Téléphone</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedAddress.telephone" id="telephone" name="telephone" placeholder="xx xx xx xx xx" aria-label="telephone">
                    </div>
                    <div *ngIf="removeDefaultAddressError" class="alert alert-warning mt-4" role="alert">
                      <p>Pour retirer cette adresse par défaut, vous devez en définir une autre par défaut :</p>
                      <ul>
                        <li>Soit en en créant une nouvelle</li>
                        <li>Soit en en définissant une parmi celles existantes</li>
                      </ul>
                      <p class="mb-0">Suite à celà, celle-ci ne sera alors plus marquée comme adresse par défaut.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="selectedAddress.defaut" id="defaut" name="defaut" aria-label="defaut" (change)="onChangeDefaultAddress($event)">
                      <label class="form-check-label" for="defaut">Adresse par défaut ?</label>
                    </div>
                    <div class="d-flex flex-row-reverse bd-highlight my-2" *ngIf="!formNewAddress">
                      <button class="btn btn-warning" type="submit" value="submit">Valider les modifications</button>
                      <button class="btn btn-primary me-2" type="button" (click)="formNewAddress = true">Créer une nouvelle adresse</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          
          <!-- CONTACTS -->
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">          
            <div class="card m-1" *ngIf="formNewContact || contacts.length === 0">
              <h4 class="card-header">Nouveau contact</h4>
              <div class="card-body">
                <div class="card-text">
                  <form #formAddContact="ngForm" (ngSubmit)="addContact(formAddContact.value)">
                    <div class="input-group mb-2">
                      <span class="input-group-text">Nom</span>
                      <input type="text" class="form-control" ngModel id="new_contact_nom" name="nom" placeholder="Nom..." aria-label="new_contact_nom">
                      <span class="input-group-text">Prénom</span>
                      <input type="text" class="form-control" ngModel id="new_contact_prenom" name="prenom" placeholder="Prénom..." aria-label="new_contact_prenom">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Adresse e-mail</span>
                      <input type="text" class="form-control" ngModel id="new_contact_email" name="email" placeholder="Adresse e-mail..." aria-label="new_contact_email">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Téléphone</span>
                      <input type="text" class="form-control" ngModel id="new_contact_telephone" name="telephone" placeholder="xx xx xx xx xx" aria-label="new_contact_telephone">
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" ngModel id="new_contact_defaut" name="defaut" aria-label="new_contact_defaut" [disabled]="contacts.length === 0">
                      <label class="form-check-label" for="new_defaut">Contact par défaut ?</label>
                    </div>
                    <div class="d-flex flex-row-reverse bd-highlight mt-2">
                      <button class="btn btn-success" type="submit" value="submit">Valider la création</button>
                      <button class="btn btn-danger me-2" type="button" (click)="formNewContact = false" [disabled]="contacts.length === 0">Annuler</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            
            <div class="card m-1" *ngIf="!formNewContact && contacts.length > 0">
              <h4 class="card-header">Contact</h4>
              <div class="card-body">
                <div *ngIf="contact_updated" class="alert alert-success alert-dismissible fade show">
                  {{ contact_updated }}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <label class="form-label">Liste des contacts</label>
                <select [ngModel]="selectedContact?.id ?? 0" (ngModelChange)="onChangeProviderContact($event)" name="contactSelect" id="contactSelect" class="form-select mb-4">
                  <option *ngFor="let c of contacts" [ngValue]="c.id">
                    {{ c.defaut ? "Par défaut - " : "" }}{{ c.id }} - {{ c.nom.toLocaleUpperCase() }} {{ c.prenom }} - {{ c.email }}
                  </option>
                </select> 
                <div *ngIf="selectedContact" class="card-text">
                  <form #formUpdateContact="ngForm" (ngSubmit)="updateContact(selectedContact.id, formUpdateContact.value)">
                    <div class="input-group mb-2">
                      <span class="input-group-text">Nom</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedContact.nom" id="contact_nom" name="nom" placeholder="Nom..." aria-label="contact_nom">
                      <span class="input-group-text">Prénom</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedContact.prenom" id="contact_prenom" name="prenom" placeholder="Prénom..." aria-label="contact_prenom">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Adresse e-mail</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedContact.email" id="contact_email" name="email" placeholder="Adresse e-mail..." aria-label="contact_email">
                    </div>
                    <div class="input-group mb-2">
                      <span class="input-group-text">Téléphone</span>
                      <input type="text" class="form-control" [(ngModel)]="selectedContact.telephone" id="contact_telephone" name="telephone" placeholder="xx xx xx xx xx" aria-label="contact_telephone">
                    </div>
                    
                    <div *ngIf="removeDefaultContactError" class="alert alert-warning mt-4" role="alert">
                      <p>Pour retirer ce contact par défaut, vous devez en définir un autre :</p>
                      <ul>
                        <li>Soit en en créant un nouveau</li>
                        <li>Soit en en définissant un parmi ceux existants</li>
                      </ul>
                      <p class="mb-0">Suite à cela, celui-ci ne sera alors plus marqué comme contact par défaut.</p>
                    </div>
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="selectedContact.defaut" id="contact_defaut" name="defaut" aria-label="contact_defaut" (change)="onChangeDefaultContact($event)">
                      <label class="form-check-label" for="new_defaut">Contact par défaut ?</label>
                    </div>
                    <div class="d-flex flex-row-reverse bd-highlight my-2" *ngIf="!formNewContact">
                      <button class="btn btn-warning" type="submit" value="submit">Valider les modifications</button>
                      <button class="btn btn-primary me-2" type="submit" value="submit" (click)="formNewContact = true">Créer un nouveau contact</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
